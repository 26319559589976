<template>
  <div>
    <header-title>
      <h3 slot="title">사이트 정보</h3>
    </header-title>
    <div class="main main--sideType">
      <div class="main__container">
        <div class="sideLayout">
          <div class="profile">
            <div v-if="attach.url" class="profile__logo">
              <img :src="attach.url" />
            </div>
            <div v-else class="profile__no">
              <img src="@/assets/images/common/ico_image_none.svg" />
            </div>
            <button
              type="button"
              class="profile__link"
              v-if="!solutionName"
              @click="$router.push({name: 'solution'})"
            >
              외부 솔루션 연동하기
            </button>
            <div class="profile__solution" v-else>
              <img
                src="@/assets/images/common/bi_makeshop.svg"
                v-if="solutionName === '메이크샵'"
                style="height: 14px"
              />
              <img
                src="@/assets/images/common/bi_mysoho.svg"
                v-if="solutionName === '마이소호'"
                style="height: 14px"
              />
              <p>
                ID : <span>{{ solutionUserId }}</span>
              </p>
            </div>
            <p class="profile__name">
              <span v-if="siteId">{{ site.name }}</span>
              <span v-else>사이트명</span>
            </p>
            <p v-if="siteId && site.url" class="profile__domain">
              <a :href="site.url" target="_blank">
                <span>{{ site.url }}</span>
              </a>
            </p>
          </div>
        </div>
        <div class="mainLayout">
          <div class="frmLayout">
            <p class="frmLayout__guide">
              쾌적한 서비스 지원을 위해 사이트 정보를 입력해주세요.
            </p>
            <section>
              <div class="frmLayout__heading">
                <h4>사이트명</h4>
                <tooltip
                  :text="'실제 웹페이지에 제공되는 정보는 아니며, 사용자가 쉽게 구분할 수 있는 용도로 사용됩니다.'"
                />
              </div>
              <div class="frmLayout__item">
                <input
                  type="input"
                  class="frmBlock__textField"
                  :class="{invalid: site.nameError}"
                  v-model="site.name"
                  maxlength="20"
                  :placeholder="site.nameMessage"
                  @keyup="checkName"
                />
                <div class="frmBlock__error" v-show="site.nameError">
                  {{ site.nameMessage }}
                </div>
              </div>
            </section>
            <section>
              <div class="frmLayout__heading">
                <h4>대표 이미지</h4>
                <tooltip
                  :text="'큐픽 어드민에서 표시되는 이미지를 등록할 수 있습니다.<br/>･ 테마 디자인에는 사용하지 않습니다.'"
                />
              </div>
              <div class="frmLayout__item">
                <div class="frmLayout__file">
                  <div class="frmLayout__spinner" v-show="attach.loading">
                    <i class="xi-spinner-1 xi-spin"></i>
                  </div>
                  <div class="frmLayout__attach" v-show="attach.url">
                    <button type="button" @click="attachDelete">
                      이미지 삭제
                    </button>
                    <img :src="attach.url" />
                  </div>
                  <input
                    type="file"
                    @click="attachInit"
                    @change="attachAdd"
                    accept=".jpg, .jpeg, .png, .gif"
                  />
                  <dl class="frmLayout__upload">
                    <dt>이미지 업로드</dt>
                    <dd>해당 영역을 선택하여 이미지를 업로드 해주세요.</dd>
                  </dl>
                </div>
                <p class="frmLayout__text">
                  사이즈 : 가로, 세로 최대 284px 이하<br />
                  확장자 : jpg, jpeg, png, gif
                </p>
              </div>
            </section>
            <section>
              <div class="frmLayout__heading">
                <h4>도메인 주소</h4>
                <tooltip
                  :text="'도메인 주소 입력 시 https:// 포함된 경로를 입력해 주세요. 해당 정보는 사이트를 완성하신 다음에 입력하셔도 무방합니다.'"
                />
              </div>
              <div class="frmLayout__item">
                <input
                  type="input"
                  class="frmBlock__textField"
                  v-model="site.url"
                  :placeholder="site.urlMessage"
                  :class="{invalid: site.urlError}"
                  @keyup="checkUrl"
                />
                <div class="frmBlock__error" v-show="site.urlError">
                  {{ site.urlMessage }}
                </div>
              </div>
            </section>
            <section>
              <div class="frmLayout__heading">
                <h4>업종</h4>
                <tooltip
                  :text="'업종을 선택하시면 더 정확한 고객님 맞춤 콘텐츠를 제공해 드릴 수 있습니다.'"
                />
              </div>
              <div class="frmLayout__item">
                <div class="DAV-dropdown">
                  <button
                    type="button"
                    class="DAV-dropdown__button"
                    @click.stop="toggleDropdown"
                  >
                    {{
                      site.bizType !== null
                        ? site.bizType
                        : "업종을 선택하세요."
                    }}
                  </button>
                  <div :class="setDropdownClass" v-show="dropdownVisible">
                    <div class="DAV-dropdown__selected">
                      {{
                        site.bizType !== null
                          ? site.bizType
                          : "업종을 선택하세요."
                      }}
                    </div>
                    <ul v-click-outside:toggleDropdown>
                      <li v-for="(value, index) in bizTypeList" :key="index">
                        <button
                          type="button"
                          @click="selectBizType(value)"
                          :class="{'select-active': value === site.bizType}"
                        >
                          {{ value }}
                        </button>
                      </li>
                    </ul>
                    <div v-if="getDeviceType === 'mobile'" class="dimmed"></div>
                  </div>
                </div>
                <div class="frmBlock__error" v-show="site.bizTypeError">
                  {{ site.bizTypeMessage }}
                </div>
              </div>
            </section>
            <section v-if="siteId">
              <div class="frmLayout__support">
                <button type="button" @click="userSiteConfirm">
                  <span>사이트 삭제하기</span>
                </button>
                <p class="frmLayout__text">
                  사이트 삭제 시 사이트 정보가 모두 삭제됩니다.
                </p>
              </div>
            </section>
          </div>
          <button
            type="submit"
            class="main__submit main__submit--active"
            @click="userSiteSave"
          >
            사이트 정보 저장하기 <i class="xi-angle-right-min"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="footer">ⓒ QPICK</div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {checkFile} from "@/utils/file.js";

const ModuleAdmin = createNamespacedHelpers("ModuleAdmin");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleAttach = createNamespacedHelpers("ModuleAttach");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleUserSite = createNamespacedHelpers("ModuleUserSite");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      userId: null,
      siteId: null,
      attach: {
        originId: null,
        id: null,
        url: null,
        loading: false
      },
      site: {
        name: null,
        nameError: false,
        nameMessage: "사이트명을 입력해주세요.",
        url: null,
        urlError: false,
        urlMessage: "도메인 주소를 입력해주세요.",
        bizType: null,
        bizTypeError: false,
        bizTypeMessage: "업종을 선택하세요."
      },
      solutionName: null,
      solutionUserId: null,
      solutionId: null,
      dropdownVisible: false,
      repeatCount: 0,
      bizTypeList: []
    };
  },
  watch: {
    // 사이트 정보 상세
    getUserSiteReadData(data) {
      this.site.name = data.name;
      this.site.url = data.url;
      this.site.bizType = data.bizType;

      this.attach.originId = data.attachFiles?.id;
      this.attach.id = data.attachFiles?.id;
      this.attach.url = data.attachFiles?.url;

      this.solutionName = data.userSolution?.solutionName;
      this.solutionUserId = data.userSolution?.solutionUserId;
      this.solutionId = data.userSolution?.solutionId;
    },
    // 사이트 정보 추가
    getUserSiteAddData(data) {
      this.siteId = data.id;
      this.$store.dispatch("ModuleUser/actUserLog", {
        eventName: "사이트 추가",
        requestLocation: "큐픽 > 어드민",
        solutionName: this.solutionName,
        siteId: this.siteId
      });
      this.attachUpdate("add");
    },
    // 사이트 정보 수정
    getUserSiteUpdateData() {
      this.attachUpdate("update");
    },
    // 사이트 정보 삭제
    getUserSiteDeleteData() {
      this.$store.dispatch("ModuleUser/actUserLog", {
        eventName: "사이트 삭제",
        requestLocation: "큐픽 > 어드민",
        solutionName: this.solutionName,
        siteId: this.siteId
      });
      this.setAlert({
        isVisible: true,
        message: `삭제가 완료되었습니다.`,
        event: "siteDelete"
      });
    },
    dropdownVisible(v) {
      if (v && this.getDeviceType === "mobile") {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "";
      }
    }
  },
  computed: {
    // class binding - 드롭다운 메뉴
    setDropdownClass() {
      return {
        "DAV-dropdown__popup": this.getDeviceType === "mobile",
        "DAV-dropdown__list": this.getDeviceType === "desktop"
      };
    },

    ...ModuleAdmin.mapGetters(["getDeviceType"]),
    ...ModuleUserSite.mapGetters([
      "getSolutionId",
      "getUserSiteReadData",
      "getUserSiteAddData",
      "getUserSiteDeleteData",
      "getUserSiteUpdateData"
    ]),
    ...ModuleTheme.mapGetters(["getUserThemeListData"])
  },
  destroyed() {
    document.querySelector("body").style.overflow = "";
  },
  methods: {
    // 솔루션 목록
    solutionList() {
      this.actSolutionList().then(() => {
        this.solutionId = this.getSolutionId("마이소호");
        this.themeCategory();
      });
    },
    //테마 카테고리 목록 조회
    themeCategory() {
      let payload = {};
      payload.solutionId = this.solutionId;

      this.actThemeCategory(payload).then(response => {
        switch (response.status) {
          case 200:
            response.data.forEach(item => {
              this.bizTypeList.push(item.name);
            });
            break;

          default:
            break;
        }
      });
    },
    // 드롭다운 show/hide
    toggleDropdown(value) {
      this.dropdownVisible = !value ? value : !this.dropdownVisible;
    },
    // 업종 선택
    selectBizType(bizType) {
      if (bizType) {
        this.site.bizType = bizType;
      }
      this.toggleDropdown(false);
      this.checkBizType();
    },
    // 사이트명 체크
    checkName() {
      this.site.nameError = !this.site.name;
      return this.site.nameError;
    },
    // 도메인 주소 체크
    checkUrl() {
      this.site.urlError = this.site.url && !this.validURL(this.site.url);
      return this.site.urlError;
    },
    // 업종 체크
    checkBizType() {
      this.site.bizTypeError =
        !this.site.bizType || this.site.bizType === this.site.bizTypeMessage;
      return this.site.bizTypeError;
    },
    // 사이트 정보 저장
    userSiteSave() {
      if (this.checkName()) {
        this.setAlert({
          isVisible: true,
          message: `사이트명을 입력해주세요.`
        });
        return;
      }

      if (this.checkUrl()) {
        this.setAlert({
          isVisible: true,
          message: `도메인 주소를 입력해주세요.`
        });
        return;
      }

      let payload = {};
      payload.userId = this.userId;
      payload.name = this.site.name;
      payload.solutionId = this.solutionId;
      payload.url = this.site.url;
      payload.bizType = this.site.bizType;

      if (this.siteId) {
        if (
          this.site.name !== this.getUserSiteReadData.name ||
          this.site.url !== this.getUserSiteReadData.url ||
          this.site.bizType !== this.getUserSiteReadData.bizType
        ) {
          this.$store.dispatch("ModuleUser/actUserLog", {
            eventName: "사이트 정보 수정",
            requestLocation: "큐픽 > 어드민",
            solutionName: this.solutionName,
            siteId: this.siteId
          });
        }
        // 사이트 정보 수정
        payload.siteId = this.siteId;
        this.actUserSiteUpdate(payload).then(() => {});
      } else {
        // 사이트 정보 추가
        this.actUserSiteAdd(payload).then(() => {});
      }
    },
    // 사이트 정보 상세
    userSiteRead() {
      let payload = {};
      payload.userId = this.userId;
      payload.siteId = this.siteId;

      this.actUserSiteRead(payload).then(response => {
        if (response.status === 403 || response.status === 404) {
          this.setAlert({
            isVisible: true,
            message: "접근 권한이 없습니다.",
            event: "back"
          });
        }
      });
    },
    // 사이트 정보 삭제 Confirm
    userSiteConfirm() {
      if (this.solutionName) {
        // 솔루션 연동이 되어 있는 경우
        this.setAlert({
          isVisible: true,
          message: `솔루션 연동 해제 후 사이트를 삭제할 수 있습니다.`
        });
      } else {
        // 솔루션 연동이 해제되어 있는 경우
        this.searchUserTheme();
      }
    },
    //유저 테마 조회
    searchUserTheme() {
      this.actUserThemeList().then(response => {
        if (response.status === 200) {
          //조회한 사이트와 일치하는 유저테마 추출
          let target = [];
          const themeList = response.data;

          themeList.forEach(theme => {
            if (theme.userSiteId === Number(this.siteId)) {
              target.push(theme);
            }
          });

          //삭제 컨펌
          this.setConfirm({
            isVisible: true,
            message: `사이트에 생성된 테마가 모두 삭제되며<br />복구할 수 없습니다. 사이트를 삭제하시겠습니까?`,
            messageBtn: `사이트 삭제하기`,
            event: () =>
              themeList.length > 0
                ? this.userThemeDelete(target)
                : this.userSiteDelete()
          });
        } else {
          this.setAlert({
            isVisible: true,
            message: response.data.errors[0].userMsg
          });
        }
      });
    },
    //회원 테마 삭제
    userThemeDelete(target) {
      target.forEach(item => {
        let payload = {};
        payload.userThemeId = item.id;

        this.actUserThemeDelete(payload).then(response => {
          //테마삭제 완료시 사이트 삭제
          if (response.status === 204) {
            this.repeatCount = this.repeatCount + 1;

            //테마갯수만큼 테마삭제 반복 완료시 사이트 삭제
            if (this.repeatCount === target.length) {
              this.userSiteDelete();
            }
          }
        });
      });
    },
    // 사이트 정보 삭제
    userSiteDelete() {
      let payload = {};
      payload.userId = this.userId;
      payload.siteId = this.siteId;

      this.actUserSiteDelete(payload)
        .then(() => {
          //사이트 정보 갱신용 재조회
          this.actLogged();
        })
        .finally(() => {
          this.repeatCount = 0;
        });
    },
    // 첨부파일 임시 저장 초기화
    attachInit(e) {
      e.target.value = null;
    },
    // 첨부파일 임시 저장
    attachAdd(e) {
      let file = e.target.files[0];

      if (file) {
        let validFile = checkFile(file);
        if (!validFile.result) {
          this.setAlert({
            isVisible: true,
            message: validFile.msg
          });
          return;
        }
      }

      this.attach.loading = true;

      let payload = {};
      payload.files = file;

      this.actAttachAdd(payload).then(response => {
        this.attach.loading = false;
        this.attach.id = response.data.id;
        this.attach.url = response.data.url;
      });
    },
    // 첨부파일 삭제
    attachDelete() {
      this.attach.loading = true;

      let payload = {};
      payload.id = this.attach.id;

      this.actAttachDelete(payload).then(() => {
        this.attach.loading = false;
        this.attach.id = null;
        this.attach.url = null;
      });
    },
    // 첨부파일 수정
    attachUpdate(type) {
      if (
        !this.attach.id ||
        !this.siteId ||
        this.attach.originId === this.attach.id
      ) {
        this.setAlert({
          isVisible: true,
          message: `저장이 완료되었습니다.`,
          event: "siteUpdate"
        });
        return;
      }

      let payload = {};
      payload.id = this.attach.id;
      payload.typeId = this.siteId;
      payload.type = "user_site";
      payload.thumbnail = 0;

      this.actAttachUpdate(payload).then(() => {
        if (type === "update") {
          this.$store.dispatch("ModuleUser/actUserLog", {
            eventName: "사이트 대표 이미지 수정",
            requestLocation: "큐픽 > 어드민",
            solutionName: this.solutionName,
            siteId: this.siteId
          });
        }
        this.setAlert({
          isVisible: true,
          message: `저장이 완료되었습니다.`,
          event: "siteUpdate"
        });
      });
    },
    // URL Validation
    validURL(url) {
      // eslint-disable-next-line
      let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      return regex.test(url);
    },

    ...ModuleAdmin.mapMutations(["setDeviceType", "setBizType"]),
    ...ModuleAttach.mapActions([
      "actAttachAdd",
      "actAttachDelete",
      "actAttachUpdate"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleUserSite.mapActions([
      "actSolutionList",
      "actUserSiteRead",
      "actUserSiteAdd",
      "actUserSiteDelete",
      "actUserSiteUpdate"
    ]),
    ...ModuleTheme.mapActions([
      "actUserThemeDelete",
      "actUserThemeList",
      "actThemeCategory"
    ]),
    ...ModuleAuth.mapActions(["actLogged"])
  },
  mounted() {
    this.userId = localStorage.getItem("userId");
    this.siteId = this.$route.params.siteId;
    this.solutionList();

    if (this.siteId) {
      this.userSiteRead();
    }
  },
  created() {
    // alert
    this.$EventBus.$on("alertCancel", event => {
      // alert: 삭제 완료
      if (event === "siteDelete") {
        this.$router.push(`/admin/dashboard`);
      }
      // alert: 추가/수정 완료
      if (event === "siteUpdate") {
        this.$router.push({
          name: "site",
          params: {siteId: this.siteId}
        });
      }
      if (event === "back") {
        this.$router.go(-1) || window.close();
      }
    });
  },
  components: {
    HeaderTitle: () => import("@/components/admin/HeaderTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.frmLayout {
  &__guide {
    padding: 24px 16px 0;
    color: #309574;
    font-size: 14px;
  }
  section {
    padding: 0 16px;
    border-bottom: 8px solid #f7f9fc;

    &:last-child {
      border-bottom: 0;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    padding: 20px 0 12px;
    position: relative;

    h4 {
      display: inline-block;
      color: #606060;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__item {
    padding: 16px 0 24px;
    border-top: 1px solid #f1f1f1;
  }
  &__support {
    padding: 24px 0;

    button {
      width: 100%;
      height: 48px;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 8px;
      color: #b7b7b7;
      font-size: 14px;

      span {
        padding-left: 20px;
        background-image: url($path + "ico_trash.svg");
        background-repeat: no-repeat;
        background-position: 0 50%;
      }
    }
  }
  &__text {
    margin-top: 12px;
    color: #acaeb2;
    font-size: 12px;
  }
  &__file {
    position: relative;
    width: 284px;
    height: 284px;
    background: #f7f9fc;

    input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
  &__spinner {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f9fc;
    border: 1px dashed #acaeb2;

    i {
      color: #309574;
      font-size: 30px;
    }
  }
  &__attach {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    border: 1px solid #dcdcdc;
    background: #f7f9fc;

    button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      background-image: url($path + "ico_close.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      text-indent: -9999px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: #acaeb2;
    text-align: center;
    border: 1px dashed #acaeb2;
    box-sizing: border-box;

    dt {
      padding-top: 56px;
      background-image: url($path + "ico_upload.svg");
      background-repeat: no-repeat;
      background-position: 50% 0;
    }
    dd {
      margin-top: 30px;
      font-size: 12px;
    }
  }
}
.main {
  &--sideType {
    padding: 0;
  }
  &__container {
    display: flex;
    @include box-revert;
  }
}

.footer {
  margin: 40px 0;
  color: #8d8d8d;
  font-weight: 500;
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: 1023px) {
    margin-top: -40px;
    margin-bottom: 100px;
  }
}

.sideLayout {
  width: 290px;
  height: 318px;
  margin-right: 16px;
  box-sizing: border-box;
  @include box;
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 42px;

  &__logo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__no {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__solution {
    margin-top: 24px;
    margin-bottom: -4px;
    text-align: center;

    p {
      margin-top: -8px;
      color: #484948;
      font-size: 14px;

      span {
        font-size: 16px;
      }
    }
  }
  &__link {
    margin-top: 24px;
    padding: 0 10px;
    height: 32px;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
  }
  &__name {
    margin-top: 16px;
    color: #484948;
    font-size: 18px;
    font-weight: 700;
    @include text-ellipsis;
  }
  &__domain {
    color: #8d8d8d;
    font-size: 12px;

    span {
      padding-right: 18px;
      background-image: url($path + "ico_linked.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      color: #8d8d8d;
      font-size: 12px;
    }
  }
}
.mainLayout {
  position: relative;
  width: 942px;
  min-height: 300px;
  margin-bottom: 84px;
  box-sizing: border-box;
  @include box;
}
.main__submit {
  z-index: 2;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -84px;
  height: 64px;
  background: #dcdcdc;
  border-radius: 8px;
  color: #ffffff;
  font-size: 18px;

  &--active {
    background: #54c7a2;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .main {
    &__container {
      flex-direction: column;
      padding: 0;
    }
  }
  .sideLayout {
    width: 100%;
    border-bottom: 8px solid #f7f9fc;
    box-shadow: unset;
    border-radius: unset;
  }
  .mainLayout {
    width: 100%;
    box-shadow: unset;
    border-radius: unset;
  }
  .main__submit {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    width: 100%;
  }
}
</style>
