var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header-title', [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("사이트 정보")])]), _c('div', {
    staticClass: "main main--sideType"
  }, [_c('div', {
    staticClass: "main__container"
  }, [_c('div', {
    staticClass: "sideLayout"
  }, [_c('div', {
    staticClass: "profile"
  }, [_vm.attach.url ? _c('div', {
    staticClass: "profile__logo"
  }, [_c('img', {
    attrs: {
      "src": _vm.attach.url
    }
  })]) : _c('div', {
    staticClass: "profile__no"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_image_none.svg")
    }
  })]), !_vm.solutionName ? _c('button', {
    staticClass: "profile__link",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'solution'
        });
      }
    }
  }, [_vm._v(" 외부 솔루션 연동하기 ")]) : _c('div', {
    staticClass: "profile__solution"
  }, [_vm.solutionName === '메이크샵' ? _c('img', {
    staticStyle: {
      "height": "14px"
    },
    attrs: {
      "src": require("@/assets/images/common/bi_makeshop.svg")
    }
  }) : _vm._e(), _vm.solutionName === '마이소호' ? _c('img', {
    staticStyle: {
      "height": "14px"
    },
    attrs: {
      "src": require("@/assets/images/common/bi_mysoho.svg")
    }
  }) : _vm._e(), _c('p', [_vm._v(" ID : "), _c('span', [_vm._v(_vm._s(_vm.solutionUserId))])])]), _c('p', {
    staticClass: "profile__name"
  }, [_vm.siteId ? _c('span', [_vm._v(_vm._s(_vm.site.name))]) : _c('span', [_vm._v("사이트명")])]), _vm.siteId && _vm.site.url ? _c('p', {
    staticClass: "profile__domain"
  }, [_c('a', {
    attrs: {
      "href": _vm.site.url,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.site.url))])])]) : _vm._e()])]), _c('div', {
    staticClass: "mainLayout"
  }, [_c('div', {
    staticClass: "frmLayout"
  }, [_c('p', {
    staticClass: "frmLayout__guide"
  }, [_vm._v(" 쾌적한 서비스 지원을 위해 사이트 정보를 입력해주세요. ")]), _c('section', [_c('div', {
    staticClass: "frmLayout__heading"
  }, [_c('h4', [_vm._v("사이트명")]), _c('tooltip', {
    attrs: {
      "text": '실제 웹페이지에 제공되는 정보는 아니며, 사용자가 쉽게 구분할 수 있는 용도로 사용됩니다.'
    }
  })], 1), _c('div', {
    staticClass: "frmLayout__item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.site.name,
      expression: "site.name"
    }],
    staticClass: "frmBlock__textField",
    class: {
      invalid: _vm.site.nameError
    },
    attrs: {
      "type": "input",
      "maxlength": "20",
      "placeholder": _vm.site.nameMessage
    },
    domProps: {
      "value": _vm.site.name
    },
    on: {
      "keyup": _vm.checkName,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.site, "name", $event.target.value);
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.site.nameError,
      expression: "site.nameError"
    }],
    staticClass: "frmBlock__error"
  }, [_vm._v(" " + _vm._s(_vm.site.nameMessage) + " ")])])]), _c('section', [_c('div', {
    staticClass: "frmLayout__heading"
  }, [_c('h4', [_vm._v("대표 이미지")]), _c('tooltip', {
    attrs: {
      "text": '큐픽 어드민에서 표시되는 이미지를 등록할 수 있습니다.<br/>･ 테마 디자인에는 사용하지 않습니다.'
    }
  })], 1), _c('div', {
    staticClass: "frmLayout__item"
  }, [_c('div', {
    staticClass: "frmLayout__file"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.attach.loading,
      expression: "attach.loading"
    }],
    staticClass: "frmLayout__spinner"
  }, [_c('i', {
    staticClass: "xi-spinner-1 xi-spin"
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.attach.url,
      expression: "attach.url"
    }],
    staticClass: "frmLayout__attach"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.attachDelete
    }
  }, [_vm._v(" 이미지 삭제 ")]), _c('img', {
    attrs: {
      "src": _vm.attach.url
    }
  })]), _c('input', {
    attrs: {
      "type": "file",
      "accept": ".jpg, .jpeg, .png, .gif"
    },
    on: {
      "click": _vm.attachInit,
      "change": _vm.attachAdd
    }
  }), _vm._m(0)]), _vm._m(1)])]), _c('section', [_c('div', {
    staticClass: "frmLayout__heading"
  }, [_c('h4', [_vm._v("도메인 주소")]), _c('tooltip', {
    attrs: {
      "text": '도메인 주소 입력 시 https:// 포함된 경로를 입력해 주세요. 해당 정보는 사이트를 완성하신 다음에 입력하셔도 무방합니다.'
    }
  })], 1), _c('div', {
    staticClass: "frmLayout__item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.site.url,
      expression: "site.url"
    }],
    staticClass: "frmBlock__textField",
    class: {
      invalid: _vm.site.urlError
    },
    attrs: {
      "type": "input",
      "placeholder": _vm.site.urlMessage
    },
    domProps: {
      "value": _vm.site.url
    },
    on: {
      "keyup": _vm.checkUrl,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.site, "url", $event.target.value);
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.site.urlError,
      expression: "site.urlError"
    }],
    staticClass: "frmBlock__error"
  }, [_vm._v(" " + _vm._s(_vm.site.urlMessage) + " ")])])]), _c('section', [_c('div', {
    staticClass: "frmLayout__heading"
  }, [_c('h4', [_vm._v("업종")]), _c('tooltip', {
    attrs: {
      "text": '업종을 선택하시면 더 정확한 고객님 맞춤 콘텐츠를 제공해 드릴 수 있습니다.'
    }
  })], 1), _c('div', {
    staticClass: "frmLayout__item"
  }, [_c('div', {
    staticClass: "DAV-dropdown"
  }, [_c('button', {
    staticClass: "DAV-dropdown__button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleDropdown.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.site.bizType !== null ? _vm.site.bizType : "업종을 선택하세요.") + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible,
      expression: "dropdownVisible"
    }],
    class: _vm.setDropdownClass
  }, [_c('div', {
    staticClass: "DAV-dropdown__selected"
  }, [_vm._v(" " + _vm._s(_vm.site.bizType !== null ? _vm.site.bizType : "업종을 선택하세요.") + " ")]), _c('ul', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside:toggleDropdown",
      arg: "toggleDropdown"
    }]
  }, _vm._l(_vm.bizTypeList, function (value, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      class: {
        'select-active': value === _vm.site.bizType
      },
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.selectBizType(value);
        }
      }
    }, [_vm._v(" " + _vm._s(value) + " ")])]);
  }), 0), _vm.getDeviceType === 'mobile' ? _c('div', {
    staticClass: "dimmed"
  }) : _vm._e()])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.site.bizTypeError,
      expression: "site.bizTypeError"
    }],
    staticClass: "frmBlock__error"
  }, [_vm._v(" " + _vm._s(_vm.site.bizTypeMessage) + " ")])])]), _vm.siteId ? _c('section', [_c('div', {
    staticClass: "frmLayout__support"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.userSiteConfirm
    }
  }, [_c('span', [_vm._v("사이트 삭제하기")])]), _c('p', {
    staticClass: "frmLayout__text"
  }, [_vm._v(" 사이트 삭제 시 사이트 정보가 모두 삭제됩니다. ")])])]) : _vm._e()]), _c('button', {
    staticClass: "main__submit main__submit--active",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.userSiteSave
    }
  }, [_vm._v(" 사이트 정보 저장하기 "), _c('i', {
    staticClass: "xi-angle-right-min"
  })])])])]), _c('div', {
    staticClass: "footer"
  }, [_vm._v("ⓒ QPICK")])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "frmLayout__upload"
  }, [_c('dt', [_vm._v("이미지 업로드")]), _c('dd', [_vm._v("해당 영역을 선택하여 이미지를 업로드 해주세요.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "frmLayout__text"
  }, [_vm._v(" 사이즈 : 가로, 세로 최대 284px 이하"), _c('br'), _vm._v(" 확장자 : jpg, jpeg, png, gif ")]);
}]

export { render, staticRenderFns }